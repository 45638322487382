import type React from 'react';
import { I18n } from 'util/translations';

export const ChangePasswordConfirmation: React.VFC = () => (
  <div id="login">
    <h2>{I18n.t('change_password_succeeded')}</h2>
    <div className="options">
      <a href="/edit-cv/default">{I18n.t('go_back_to_cv')}</a>
    </div>
  </div>
);
