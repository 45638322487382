import type React from "react";
import Dropdown from "components/shared/Dropdown";
import type { Office } from "schema/shared";
import { I18n } from "util/translations";

export const SelectOffice: React.FC<{
  offices: readonly Office[];
  selected_office_id?: string;
  selected_office_id_callback: (office_id: string) => void;
}> = (props) => (
  <div className="block_section_wrapper">
    {props.selected_office_id ? (
      <Dropdown
        value={props.selected_office_id}
        options={props.offices.map((office) => ({
          value: office._id,
          name: office.name!,
        }))}
        onChange={props.selected_office_id_callback}
        label="settings_labels.choose_office"
      />
    ) : (
      <>
        <label className="steps left_steps">
          {I18n.t("settings_labels.choose_office")}
        </label>
        <label className="red_label steps left_steps">
          {I18n.t("no_departments_added_to_country")}
        </label>
      </>
    )}
  </div>
);
