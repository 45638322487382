import type React from 'react';
import { Link } from 'react-router-dom';
import { I18n } from 'util/translations';

export const Logout: React.VFC = () => (
  <div id="login">
    <h2>{I18n.t('signout_message')}</h2>
    <p className="success">{I18n.t('signed_out')}</p>
    <div className="options">
      <Link className="try_again" to="/login" reloadDocument={true}>
        {I18n.t('login_again')}
      </Link>
    </div>
  </div>
);
