import type * as React from 'react';
import { Link } from 'react-router-dom';
import { I18n } from 'util/translations';

export const ResetPasswordReceipt: React.VFC = () => (
  <div id="login">
    <h2>{I18n.t('reset_password_receipt')}</h2>
    <p className="confirmation">{I18n.t('reset_password_receipt_message')}</p>
    <div className="align_wrapper_center options">
      <Link className="troubleshoot" to="/login/troubleshoot">
        {I18n.t('troubleshoot_login_click')}
      </Link>
    </div>
  </div>
);
