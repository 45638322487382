import type * as React from 'react';
import { Form } from './shared/Form';

interface User {
  id: string;
  name: string;
  email: string;
}

export const LoginAdminAs: React.VFC<{ users: readonly User[] }> = ({
  users,
}) => (
  <div className="block_container search_container search_results_container">
    <div className="block_section_wrapper extra_margin_top">
      {users.length === 0 ? (
        <div className="search_categories_container">
          <div className="search_category label">
            No international managers found. Is the subdomain correct?
          </div>
        </div>
      ) : (
        <div className="search_categories_container">
          <div className="search_category label">
            Sign in as: (international managers only)
          </div>
          <div className="interactive_cards without_bottom_margin">
            {users.map((user) => (
              <li
                key={user.id}
                className="align_wrapper_left larger_min_width twenty_width"
              >
                <Form action="/login/as">
                  <div className="inner_container equal_padding with_secondary">
                    <div className="content_block title margin_bottom_tiny">
                      {user.name}
                    </div>
                    <div className="content_block">
                      <span>ID:</span>
                      <span>{user.id}</span>
                    </div>
                    <div className="secondary_actions_block">
                      <input type="hidden" name="user_id" value={user.id} />
                      <input type="submit" value={user.email} />
                    </div>
                  </div>
                </Form>
              </li>
            ))}
          </div>
        </div>
      )}
    </div>
  </div>
);
