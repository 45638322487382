import type React from 'react';
import { I18n } from 'util/translations';

export const SelectDomain: React.VFC<{ googleAppsDomains: readonly any[] }> = ({
  googleAppsDomains,
}) => (
  <div id="login">
    <h2>{I18n.t('select_domain')}</h2>
    <div className="align_wrapper_center options">
      {googleAppsDomains.map((googleAppsDomain) => (
        <a
          key={googleAppsDomain.domain}
          href={`/auth/google_oauth2?domain=${googleAppsDomain.domain}`}
        >
          {googleAppsDomain.domain}
        </a>
      ))}
    </div>
  </div>
);
