// AUTO-CONVERTED FROM SPROCKETS
// Original file: app/assets/javascripts/shared/components/select_country_and_office.js.jsx
import React from "react";
import _ from "underscore";
import { SelectCountry as ViewsSharedSelectCountry } from "auto-converted/views/shared/SelectCountry";
import { SelectOffice as ViewsSharedSelectOffice } from "auto-converted/views/shared/SelectOffice";
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Removed namespace declaration

export class SelectCountryAndOffice extends React.Component {
  handle_change = (new_country_id) => {
    const new_valid_office_id = this.valid_office_id_for(new_country_id);
    return this.props.selected_country_and_office_id_callback(
      new_country_id,
      new_valid_office_id,
    );
  };

  offices_for = (country_id) => {
    const selected_country = _.find(this.props.countries, (country) => {
      return country._id === country_id;
    });
    if (!selected_country) {
      return [];
    }
    return selected_country.offices;
  };

  office_ids_for = (country_id) => {
    return this.offices_for(country_id).map((office) => {
      return office._id;
    });
  };

  valid_office_id_for = (country_id) => {
    const office_ids = this.office_ids_for(country_id);
    if (_.contains(office_ids, this.props.selected_office_id)) {
      return this.props.selected_office_id;
    }
    return _.first(office_ids);
  };

  selected_office_id_callback = (selected_office_id) => {
    return this.props.selected_country_and_office_id_callback(
      this.props.selected_country_id,
      selected_office_id,
    );
  };

  render() {
    return (
      <div>
        <div className="select_country_inline_wrapper relative_wrapper inlineblock_wrapper two_column">
          <ViewsSharedSelectCountry
            countries={this.props.countries}
            selected_country_id={this.props.selected_country_id}
            country_change={this.handle_change}
          />
        </div>
        {this.props.selected_country_id && (
          <div className="select_office_wrapper relative_wrapper inlineblock_wrapper two_column">
            <ViewsSharedSelectOffice
              offices={this.offices_for(this.props.selected_country_id)}
              selected_office_id={this.props.selected_office_id}
              selected_office_id_callback={this.selected_office_id_callback}
            />
          </div>
        )}
      </div>
    );
  }
}
