import type React from 'react';
import { I18n } from 'util/translations';
import { Form } from './shared/Form';

export const Timeout: React.VFC = () => (
  <div id="partners">
    <div id="login" className="timeout">
      <Form action="/login">
        <p className="error">{I18n.t('error_timeout')}</p>
        <h1>{I18n.t('Login')}</h1>
        <ul>
          <li>
            <label htmlFor="email_field">{I18n.t('EmailLabel')}</label>
            <input
              className="fillIn empty"
              id="email_field"
              name="epost"
              type="email"
              required
              placeholder={I18n.t('EmailPlaceholder')}
            />
          </li>
          <li>
            <label htmlFor="password_field">{I18n.t('PasswordLabel')}</label>
            <input
              className="fillIn empty"
              id="password_field"
              name="password"
              type="password"
              required
              placeholder={I18n.t('PasswordPlaceholder')}
            />
          </li>
        </ul>
        <div className="checkbox_handler">
          <input
            id="login_checkbox"
            type="checkbox"
            name="postpone_expiry_time"
          />
          <label htmlFor="login_checkbox">
            {I18n.t('login_checkbox_after_timeout')}
          </label>
        </div>
        <input className="submit" type="submit" value={I18n.t('Login')} />
        <div className="clear" />
      </Form>
    </div>
  </div>
);
