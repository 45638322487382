import type React from 'react';
import { Link } from 'react-router-dom';
import { useEmailAddress } from 'util/hooks/useDomain';
import { I18n } from 'util/translations';

export const LoginError: React.VFC<{
  message: string;
  errParams: Record<string, string>;
  supportEmail: string;
}> = ({ message, errParams, supportEmail }) => {
  const defaultSupportEmail = useEmailAddress('support');

  return (
    <div id="login">
      <h2>{I18n.t('sign_in_failed')}</h2>
      <p className="error">
        {I18n.t(`errors.login.${message}`, { ...errParams })}
      </p>

      {message !== 'nxuser_or_invalid_password' &&
        message !== 'user_not_external' && (
          <p className="info">
            {I18n.t('please_contact_manager')}
            {supportEmail !== defaultSupportEmail && (
              <>
                {' '}
                {I18n.t('please_contact_manager_at')} {supportEmail}
              </>
            )}
          </p>
        )}

      {message !== 'user_not_external' && message !== 'user_deactivated' && (
        <div className="align_wrapper_center options">
          <Link className="try_again" to="/login" reloadDocument={true}>
            {I18n.t('another_try_click')}
          </Link>
          <Link className="forgot_password" to="/forgotpassword">
            {I18n.t('forgot_password_click')}
          </Link>
          <Link className="troubleshoot" to="/login/troubleshoot">
            {I18n.t('troubleshoot_login_click')}
          </Link>
        </div>
      )}
    </div>
  );
};
