import React from 'react';
import { http } from 'util/fetch';
import { I18n } from 'util/translations';

interface pcProps {
  initial_password_error: string;
  user_name: string;
  user_birthday: string;
  submit_path: string;
  reset_password_code?: string;
  user_id?: string;
  display: {
    title: string;
    old_password?: string;
    new_password: string;
    submit: string;
  };
}

interface pcState {
  password_error: string;
  old_password: string;
  new_password: string;
  passwordIsDictionary?: boolean;
  passwordIsRepetitive?: boolean;
  passwordIsContextual?: boolean;
  passwordIsShort?: boolean;
}

export class PasswordChange extends React.PureComponent<pcProps, pcState> {
  constructor(props: pcProps) {
    super(props);
    this.state = {
      password_error: props.initial_password_error || '',
      old_password: '',
      new_password: '',
      passwordIsDictionary: undefined,
      passwordIsRepetitive: undefined,
      passwordIsContextual: undefined,
      passwordIsShort: undefined,
    };
  }

  handle_old_password_changed = (e) => {
    return this.setState({ old_password: e.target.value, password_error: '' });
  };

  handle_new_password_changed = (e) => {
    this.run_passwordyness_checks(e.target.value);
    return this.setState({ new_password: e.target.value });
  };

  handle_submit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const data = new FormData();
    data.append('old_password', this.state.old_password);
    data.append('new_password', this.state.new_password);
    data.append('reset_password_code', this.props.reset_password_code || '');
    data.append('user_id', this.props.user_id || '');
    const json: any = await http.post(this.props.submit_path, { body: data });
    this.setState({ password_error: json.password_error || '' });
    if (json.new_path) {
      window.location = json.new_path;
    }
  };

  handle_key_down = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      return this.handle_submit(e);
    }
  };

  run_passwordyness_checks = (candidate) => {
    if (candidate == '') {
      this.setState({
        passwordIsDictionary: undefined,
        passwordIsRepetitive: undefined,
        passwordIsContextual: undefined,
        passwordIsShort: undefined,
      });
    } else {
      this.setState({
        passwordIsDictionary: this.is_password_dictionary(candidate),
        passwordIsRepetitive: this.is_password_repetitive(candidate),
        passwordIsContextual: this.is_password_contextual(candidate),
        passwordIsShort: this.is_password_short(candidate),
      });
    }
  };

  is_password_dictionary = (candidate) => {
    return candidate.toLowerCase() == 'dictionary'; // will be replaced later with actual dictionary check
  };

  is_password_repetitive = (candidate) => {
    const l = Math.floor(candidate.length / 2);
    return candidate.includes(candidate.substring(0, l), l);
  };

  is_password_contextual = (candidate) => {
    if (candidate.toLowerCase().includes(this.props.user_name.toLowerCase())) {
      return true;
    }
    if (candidate.includes(this.props.user_birthday)) {
      return true;
    }
    const birth_year = this.props.user_birthday.substring(0, 4);
    if (candidate.includes(birth_year)) {
      return true;
    }

    return false;
  };

  is_password_short = (candidate) => {
    return candidate.length < 8;
  };

  guide_class = (truefalseundef) => {
    if (truefalseundef == true) {
      return 'invalid';
    } else if (truefalseundef == false) {
      return 'valid';
    }
  };

  guide_symbol = (truefalseundef) => {
    if (truefalseundef == true) {
      return '✘';
    } else if (truefalseundef == false) {
      return '✓';
    } else {
      return '✘';
    }
  };

  render_password_guide = (state_of_it, i18n_name) => {
    return (
      <div className={this.guide_class(state_of_it)}>
        <span>{this.guide_symbol(state_of_it)}</span>
        <span> </span>
        <span>{I18n.t(`password_validation_guidelines.${i18n_name}`)}</span>
      </div>
    );
  };

  render() {
    return (
      <div id="login">
        <form onSubmit={this.handle_submit}>
          <h2>{I18n.t(this.props.display.title)}</h2>
          <ul>
            {this.props.display.old_password && (
              <li>
                <label>
                  {I18n.t(this.props.display.old_password)}
                  <input
                    className="fillIn empty"
                    name="old_password"
                    type="password"
                    required={true}
                    onChange={this.handle_old_password_changed}
                  />
                </label>
              </li>
            )}
            <li>
              <label>
                {I18n.t(this.props.display.new_password)}
                <input
                  className="fillIn empty"
                  name="new_password"
                  type="password"
                  required={true}
                  onChange={this.handle_new_password_changed}
                />
              </label>
            </li>
          </ul>
          <div className="form_validation_info">
            {this.render_password_guide(
              this.state.passwordIsRepetitive,
              'repetitive',
            )}
            {this.render_password_guide(
              this.state.passwordIsContextual,
              'contains_contextual_information',
            )}
            {this.render_password_guide(
              this.state.passwordIsShort,
              'too_short',
            )}
          </div>
          <input
            className="submit"
            type="submit"
            value={I18n.t(this.props.display.submit)}
          />
          {this.state.password_error && (
            <p className="error without_bottom_margin with_half_top_margin">
              {this.state.password_error}
            </p>
          )}
        </form>
      </div>
    );
  }
}
