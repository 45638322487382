import type React from 'react';
import { I18n } from 'util/translations';
import { Form } from './shared/Form';

export const OtpLogin: React.VFC = () => (
  <div id="partners">
    <div id="login">
      <Form action="/login/otp">
        <h1>{I18n.t('Login')}</h1>
        <ul>
          <li>
            <label htmlFor="otp_code">{I18n.t('OTP_code')}</label>
            <input
              className="fillIn empty"
              id="otp_code"
              name="otp_code"
              type="number"
              required
              placeholder="123456"
              autoFocus
            />
          </li>
        </ul>
        <input className="submit" type="submit" value={I18n.t('Login')} />
        <div className="clear" />
      </Form>
    </div>
  </div>
);
