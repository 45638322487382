import type React from 'react';
import { I18n } from 'util/translations';
import { Form } from './shared/Form';

export const ForgotPassword: React.VFC = () => (
  <div id="login">
    <Form action="/forgotpassword">
      <h2>{I18n.t('Forgot_password')}</h2>
      <ul>
        <li>
          <label htmlFor="email_field">{I18n.t('EmailLabel')}</label>
          <input
            className="fillIn empty"
            id="email_field"
            name="email"
            type="email"
            required
            placeholder={I18n.t('EmailPlaceholder')}
            autoFocus
          />
        </li>
      </ul>
      <input
        className="submit"
        type="submit"
        value={I18n.t('send_reset_password_token')}
      />
    </Form>
  </div>
);
