import type * as React from 'react';
import { getCsrfToken } from 'util/fetch';

export const Form: React.VFC<{
  action: string;
  children: React.ReactNode;
}> = ({ action, children }) => (
  <form action={action} method="POST">
    {children}
    <input
      type="hidden"
      name="authenticity_token"
      value={getCsrfToken()}
      autoComplete="off"
    />
  </form>
);
