import type React from 'react';
import { I18n } from 'util/translations';
import { Form } from './shared/Form';

export const ForceResetPassword: React.VFC = () => (
  <div id="login">
    <Form action="/force_reset_password">
      <h2>{I18n.t('force_reset_password')}</h2>
      <div className="options">
        <p>{I18n.t('force_reset_password_explanation')}</p>
      </div>
      <input
        className="submit"
        type="submit"
        value={I18n.t('force_reset_password_action')}
      />
    </Form>
  </div>
);
