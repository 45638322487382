import type React from 'react';
import { Link } from 'react-router-dom';
import { useEmailAddress } from 'util/hooks/useDomain';
import { I18n } from 'util/translations';

export const OauthFailure: React.VFC<{
  message: string;
  errParams: Record<string, string>;
}> = ({ message, errParams }) => {
  const email = useEmailAddress('support');

  return (
    <div id="login">
      <h2>{I18n.t('sign_in_failed')}</h2>
      <p className="error">
        {I18n.t(`errors.login.${message}`, { default: '', ...errParams })}
      </p>
      <div className="align_wrapper_center options">
        <Link className="try_again" to="/login" reloadDocument={true}>
          {I18n.t('another_try_click')}
        </Link>
        <p>
          {I18n.t('keep_seeing_login_failed_message')}
          <a href={`mailto:${email}`}>{email}</a>
        </p>
      </div>
    </div>
  );
};
