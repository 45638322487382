import type React from 'react';
import { Link } from 'react-router-dom';
import { I18n } from 'util/translations';

export const UnblockLoginError: React.VFC = () => (
  <div id="login">
    <h2>{I18n.t('unblock_login_failed')}</h2>
    <p className="error">{I18n.t('unblock_login_failed_message')}</p>
    <div className="align_wrapper_center options">
      <Link className="try_again" to="/login" reloadDocument={true}>
        {I18n.t('troubleshoot_back_to_login')}
      </Link>
    </div>
  </div>
);
