import type React from 'react';
import { Link } from 'react-router-dom';
import { I18n } from 'util/translations';
import { Form } from './shared/Form';

export const Login: React.VFC<{ action: string }> = ({ action }) => (
  <div id="partners">
    <div id="login">
      <Form action={action}>
        <h1>{I18n.t('Login')}</h1>
        <ul>
          <li>
            <label htmlFor="email_field">{I18n.t('EmailLabel')}</label>
            <input
              className="fillIn empty"
              id="email_field"
              name="epost"
              type="email"
              required
              placeholder={I18n.t('EmailPlaceholder')}
              autoFocus
            />
          </li>
          <li>
            <label htmlFor="password_field">{I18n.t('PasswordLabel')}</label>
            <input
              className="fillIn empty"
              id="password_field"
              name="password"
              type="password"
              required
              placeholder={I18n.t('PasswordPlaceholder')}
            />
          </li>
        </ul>
        <input className="submit" type="submit" value={I18n.t('Login')} />
        <div className="clear" />
        <div className="align_wrapper_right">
          <Link className="forgot_password_link" to="/forgotpassword">
            {I18n.t('Forgot_password')}
          </Link>
        </div>
      </Form>
    </div>
  </div>
);
