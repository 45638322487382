import type React from 'react';
import { I18n } from 'util/translations';
import { Form } from './shared/Form';

export const TimeoutSSO: React.VFC = () => (
  <div id="partners">
    <div id="login" className="timeout">
      <Form action="/login/timeout_sso">
        <p className="error">{I18n.t('error_timeout')}</p>
        <div className="checkbox_handler">
          <input
            id="login_checkbox"
            type="checkbox"
            name="postpone_expiry_time"
          />
          <label htmlFor="login_checkbox">
            {I18n.t('login_checkbox_after_timeout')}
          </label>
        </div>
        <input className="submit" type="submit" value={I18n.t('Login')} />
        <div className="clear" />
      </Form>
    </div>
  </div>
);
