import type React from 'react';
import { useEmailAddress } from 'util/hooks/useDomain';
import { I18n } from 'util/translations';

export const UserAuthenticatedButDoesNotExistError: React.VFC<{
  field: string;
  user_id: string;
  federationGroupMembers: readonly any[];
  supportEmail: string;
}> = ({ field, user_id, federationGroupMembers, supportEmail }) => {
  const defaultSupportEmail = useEmailAddress('support');

  return (
    <div id="login">
      <h2>{I18n.t('sign_in_failed')}</h2>
      <p className="error">
        There was no user account with {field}: <strong>{user_id}</strong>
      </p>
      {federationGroupMembers.length > 0 && (
        <p className="info">
          Did you intend to login to another subdomain within the Group? If so,
          please select the correct one of the following:
          <div className="align_wrapper_center options">
            {federationGroupMembers.map((member) => {
              const [subdomain] = member.subdomains;
              const federationGroupMemberUrl = `https://${subdomain}.cvpartner.com`;

              return (
                <a
                  key={subdomain}
                  className="try_again"
                  href={federationGroupMemberUrl}
                >
                  {federationGroupMemberUrl}
                </a>
              );
            })}
          </div>
        </p>
      )}
      <p className="info">
        If your name and email have changed lately, an administrator needs to
        change your user account accordingly.
      </p>
      <p className="info">
        If this is the first time you are logging in, an administrator has to
        create a user account for you in CV Partner.
      </p>
      <p className="info">
        Please contact your manager
        {supportEmail !== defaultSupportEmail && <> or {supportEmail}</>}
      </p>
    </div>
  );
};
