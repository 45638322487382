import type React from 'react';
import { Link } from 'react-router-dom';
import { I18n } from 'util/translations';

export const ChangePasswordError: React.VFC<{
  message: string | undefined;
}> = ({ message }) => (
  <div id="login">
    <h2>{I18n.t('change_password_failed')}</h2>
    {message && (
      <p className="error">
        {I18n.t(`errors.login.${message}`, { default: '' })}
      </p>
    )}
    <div className="align_wrapper_center options">
      <Link className="try_again" to="/change_password" reloadDocument={true}>
        {I18n.t('another_try_click')}
      </Link>
      <Link className="forgot_password" to="/forgotpassword">
        {I18n.t('forgot_password_click')}
      </Link>
    </div>
  </div>
);
