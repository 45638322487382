import { reaction } from "mobx";
import React from "react";
import _ from "underscore";
import { CountryAndOfficeIdSelector as LibCountryAndOfficeIdSelector } from "auto-converted/lib/CountryAndOfficeIdSelector";
import { Countries as CountriesStore } from "auto-converted/stores/Countries";
import { SelectCountryAndOffice as ViewsSharedSelectCountryAndOffice } from "auto-converted/views/shared/SelectCountryAndOffice";
import { CurrentUser } from "stores/CurrentUser";
import {
  type VoidFunctions,
  subscribeAndPopulateWithInitialData,
} from "stores/legacy-util";
import { I18n } from "util/translations";

type ComponentProps = Record<never, unknown>;

interface ComponentState {
  countries: any[];
  selected_country_id: false | string;
  selected_office_id: false | string;
  showing_error_message: boolean;
}

export class Show extends React.Component<ComponentProps, ComponentState> {
  unsubscribers: VoidFunctions = [];

  state: ComponentState = {
    countries: [],
    selected_country_id: false,
    selected_office_id: false,
    showing_error_message: false,
  };

  componentDidMount() {
    this.unsubscribers = [
      reaction(
        () => [CurrentUser.country_id, CurrentUser.office_id],
        this.current_user_changed,
      ),
      subscribeAndPopulateWithInitialData(
        CountriesStore,
        this.countries_changed,
      ),
    ];
  }

  componentWillUnmount() {
    for (const unsubscriber of this.unsubscribers) {
      unsubscriber();
    }
    this.unsubscribers = [];
  }

  current_user_changed = ([country_id, office_id]: (string | undefined)[]) => {
    const country_and_office_id_selector = new LibCountryAndOfficeIdSelector({
      countries: this.state.countries,
    });

    this.setState({
      selected_country_id:
        country_id || country_and_office_id_selector.new_selected_country_id(),
      selected_office_id:
        office_id || country_and_office_id_selector.new_selected_office_id(),
    });
  };

  countries_changed = (countries) => {
    const new_countries = countries["all"] || [];
    const new_state_hash = {};

    if (!_.isEqual(this.state.countries, new_countries)) {
      new_state_hash["countries"] = new_countries;
    }

    const country_and_office_id_selector = new LibCountryAndOfficeIdSelector({
      countries: new_countries,
    });
    if (!this.state.selected_country_id) {
      new_state_hash["selected_country_id"] =
        country_and_office_id_selector.new_selected_country_id();
    }
    if (!this.state.selected_office_id) {
      new_state_hash["selected_office_id"] =
        country_and_office_id_selector.new_selected_office_id();
    }

    if (Object.keys(new_state_hash).length > 0) {
      this.setState(new_state_hash);
    }
  };

  render_select_country_and_office = () => {
    if (this.state.selected_country_id === false) {
      return null;
    }
    return (
      <ViewsSharedSelectCountryAndOffice
        selected_country_id={this.state.selected_country_id}
        selected_office_id={this.state.selected_office_id}
        countries={this.state.countries}
        selected_country_and_office_id_callback={
          this.selected_country_and_office_id_callback
        }
      />
    );
  };

  selected_country_and_office_id_callback = (
    selected_country_id,
    selected_office_id,
  ) => {
    return this.setState({ selected_country_id, selected_office_id });
  };

  handle_save = () => {
    const { selected_office_id, selected_country_id } = this.state;
    if (!selected_office_id || !selected_country_id) {
      return;
    }
    const update_hash = {
      country_id: selected_country_id,
      office_id: selected_office_id,
    };
    return CurrentUser.update_country_and_office(update_hash, "/home");
  };

  render_error_message = () => {
    if (!this.state.showing_error_message) {
      return null;
    }
    return (
      <div className="error_message">
        {I18n.t("settings_labels.please_select_a_country_and_office")}
      </div>
    );
  };

  render() {
    return (
      <div id="login">
        <section id="select_your_office_section">
          <h2>{I18n.t("settings_labels.please_select_your_office")}</h2>
          <div className="settings_form">
            <div className="select_country_wrapper">
              {this.render_select_country_and_office()}
            </div>
            <input
              className="submit"
              type="submit"
              value={I18n.t("settings_labels.save")}
              onClick={this.handle_save}
            />
          </div>
        </section>
        {this.render_error_message()}
      </div>
    );
  }
}
