import React from "react";
import { name_for_legacy as name_for_legacy_country_code } from "auto-converted/lib/CountryCodeUtils";
import Dropdown from "components/shared/Dropdown";
import type { Country } from "schema/shared";

export type SelectableCountry = Pick<Country, "_id" | "code">;

export const SelectCountry: React.FC<{
  countries: readonly SelectableCountry[];
  country_change: (country_id: string) => void;
  selected_country_id: string | undefined;
  invalid?: boolean;
}> = (props) => {
  const options = React.useMemo(
    () =>
      props.countries.map((c) => ({
        value: c._id,
        name: name_for_legacy_country_code(c.code),
      })),
    [props.countries],
  );

  return (
    <div className="block_section_wrapper">
      <Dropdown
        options={options}
        field="country"
        onChange={props.country_change}
        value={props.selected_country_id}
        invalid={props.invalid}
        label="settings_labels.choose_country"
      />
    </div>
  );
};
