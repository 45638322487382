import type React from 'react';
import { Link } from 'react-router-dom';
import { I18n } from 'util/translations';

export const ResetPasswordError: React.VFC = () => (
  <div id="login">
    <h2>{I18n.t('reset_password_failed')}</h2>
    <p className="error">{I18n.t('reset_password_failed_message')}</p>
    <div className="align_wrapper_center options">
      <Link className="forgot_password" to="/forgotpassword">
        {I18n.t('reset_password_request_new_token')}
      </Link>
      <Link className="try_again" to="/login" reloadDocument={true}>
        {I18n.t('reset_password_sign_in')}
      </Link>
    </div>
  </div>
);
