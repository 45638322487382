import type React from 'react';
import { Link } from 'react-router-dom';
import { useDomain } from 'util/hooks/useDomain';
import { I18n } from 'util/translations';

export const Troubleshoot: React.VFC = () => {
  const domain = useDomain();

  return (
    <div id="login">
      <h2>{I18n.t('troubleshoot_login')}</h2>
      <div className="options">
        <p>1. {I18n.t('troubleshoot_email_casing')}</p>
        <p>2. {I18n.t('troubleshoot_use_most_recent')}</p>
        <p>3. {I18n.t('troubleshoot_clear_cookies')}</p>
        <p>4. {I18n.t('troubleshoot_blocked')}</p>
        <p>5. {I18n.t('troubleshoot_contact_support', { domain })}</p>
        <Link className="try_again" to="/login" reloadDocument={true}>
          {I18n.t('troubleshoot_back_to_login')}
        </Link>
      </div>
    </div>
  );
};
